<template>
  <!-- Education Information -->
  <div
    class="recent-activity border-2 border-grey rounded-xl py-2 w-full mt-5 text-darkGrey"
  >
    <div class="flex justify-between mx-3">
      <h2 class="text-teal">Education Information</h2>
      <div v-if="!hideEditButton" class="flex items-start cursor-pointer">
        <span class="material-icons" @click="educationInformationModal()">
          edit
        </span>
      </div>
    </div>
    <template
      v-if="getEducationInformation && getEducationInformation.length > 0"
    >
      <ul class="res-activity-list ml-3 my-2 pl-8 relative">
        <li
          v-for="education in getEducationInformation"
          :key="education.id"
          class="relative mb-2"
        >
          <p
            class="font-bold cursor-pointer"
            @click="educationInformationModal(education)"
          >
            {{ education.institution }}
          </p>
          <p class="text-xs">{{ education.subject }}</p>
          <p class="text-xs">
            {{ getDate(education.start_date) }}-{{ education.completion_date }}
          </p>
          <a
            class="text-xs underline"
            :href="education.certificate"
            target="_blank"
            >Certificate</a
          >
        </li>
      </ul>
    </template>
    <div v-else>
      <no-data-found />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import noDataFound from "@/components/ui/baseComponents/noDataFound.vue";
export default {
  emits: ["modalTrigger"],
  components: { noDataFound },
  props: {
    getEducationInformation: {
      type: Object,
      // required: true,
    },
    hideEditButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showEducationInformationModal: false,
    };
  },
  methods: {
    educationInformationModal(data) {
      this.showEducationInformationModal = !this.showEducationInformationModal;
      this.$emit("modalTrigger", data);
    },
    getDate(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped>
.recent-activity .res-activity-list li:before {
  content: "";
  position: absolute;
  border-radius: 100%;
}

.recent-activity .res-activity-list li:before {
  width: 10px;
  height: 10px;
  left: -32px;
  top: 5px;
  border: 2px solid #ed8b00;
  margin-right: 15px;
  z-index: 2;
  background: #fff;
}
.recent-activity .res-activity-list li:hover:before {
  border: 4px solid #ed8b00;
  cursor: pointer;
}
.recent-activity .res-activity-list:after {
  content: "";
  border: 1px solid #e8edee;
  position: absolute;
  top: 5px;
  bottom: 0;
  left: 4px;
}
</style>
