<template>
  <!-- Registrations -->
  <div class="">
    <div class="border-2 border-grey rounded-xl py-2 w-full mt-5 text-darkGrey">
      <div class="flex justify-between mx-3">
        <h2 class="text-teal">Registrations</h2>
        <div v-if="!hideEditButton" class="flex items-start cursor-pointer">
          <span class="material-icons" @click="registrationsModal()">
            edit
          </span>
        </div>
      </div>
      <template v-if="getRegistrations">
        <div v-if="getRegistrations.is_nurse">
          <div>
            <h3
              class="w-4/5 my-3 mx-auto overflow-hidden text-center text-orange"
            >
              NMC
            </h3>
          </div>
          <div class="flex m-2 ml-3">
            <p class="flex-1">Name:</p>
            <p class="w-2/5">
              {{ getRegistrations.name.toUpperCase() }}
            </p>
          </div>
          <div class="flex m-2 ml-3">
            <p class="flex-1">Geographical location:</p>
            <p class="w-2/5">
              {{ getRegistrations.location.toUpperCase() }}
            </p>
          </div>
          <div class="flex m-2 ml-3">
            <p class="flex-1">Registration status:</p>
            <p class="w-2/5">
              {{ getRegistrations.registration_status.toUpperCase() }}
            </p>
          </div>
          <div class="flex m-2 ml-3">
            <p class="flex-1">Register entry:</p>
            <p class="w-2/5">
              {{ getRegistrations.register_entry.toUpperCase() }}
            </p>
          </div>
          <div class="flex m-2 ml-3">
            <p class="flex-1">Register entry date:</p>
            <p class="w-2/5">
              {{ convertedTime(getRegistrations.register_entry.date) }}
            </p>
          </div>
          <div class="flex m-2 ml-3">
            <p class="flex-1">Recorded qualifications & date:</p>
            <div class="w-2/5">
              <p
                v-for="entry in getRegistrations.nmc_qualifications"
                :key="entry.id"
              >
                {{ entry.name }} - {{ convertedTime(entry.date) }}
              </p>
            </div>
          </div>
          <div class="flex m-2 ml-3">
            <p class="flex-1">NMC Document:</p>
            <p class="w-2/5">
              <a
                class="underline"
                :href="getRegistrations.nmc_document"
                target="_blank"
                >NMC Document</a
              >
            </p>
          </div>
        </div>
        <div v-if="!getRegistrations.is_nurse">
          <div>
            <h3
              class="w-4/5 my-3 mx-auto overflow-hidden text-center text-orange"
            >
              GMC
            </h3>
          </div>
          <div class="flex m-2 ml-3">
            <p class="flex-1">GMC reference no.:</p>
            <p class="w-2/5">
              {{ getRegistrations.gmc_reference_number }}
            </p>
          </div>
          <div class="flex m-2 ml-3">
            <p class="flex-1">GP register date:</p>
            <p class="w-2/5">
              {{
                convertedTime(getRegistrations.gp_register_date).toUpperCase()
              }}
            </p>
          </div>
          <div class="flex m-2 ml-3">
            <p class="flex-1">Specialist register:</p>
            <div class="w-2/5">
              <p
                v-for="entry in getRegistrations.gmc_specialist_registers"
                :key="entry.id"
              >
                {{ entry.name }}
              </p>
            </div>
          </div>
          <div class="flex m-2 ml-3">
            <p class="flex-1">Provisional register date:</p>
            <p class="w-2/5">
              {{ getRegistrations.provisional_registration_date }}
            </p>
          </div>
          <div class="flex m-2 ml-3">
            <p class="flex-1">Full registration date:</p>
            <p class="w-2/5">
              {{ getRegistrations.full_registration_date }}
            </p>
          </div>
        </div>
      </template>
      <div v-else>
        <no-data-found />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import noDataFound from "@/components/ui/baseComponents/noDataFound.vue";

export default {
  emits: ["modalTrigger"],
  components: { noDataFound },
  props: {
    getRegistrations: {
      type: Object,
      // required: true,
    },
    hideEditButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showRegistrationsModal: false,
    };
  },
  methods: {
    convertedTime(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    registrationsModal() {
      this.showRegistrationsModal = !this.showRegistrationsModal;
      this.$emit("modalTrigger", this.showRegistrationsModal);
    },
  },
};
</script>

<style scoped>
h3:before,
h3:after {
  content: "";
  color: #000;
  display: inline-block;
  width: 50%;
  margin: 0 0.5em 0 -55%;
  vertical-align: middle;
  border-bottom: 1px solid;
}
h3:after {
  margin: 0 -55% 0 0.5em;
}
</style>
