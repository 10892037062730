<template>
  <form @submit.prevent="submitModal">
    <div
      class="
        flex
        justify-center
        h-screen
        w-screen
        items-center
        z-30
        absolute
        top-0
        left-0
        -translate-x-1/2
      "
    >
      <div
        class="h-screen w-screen bg-grey bg-opacity-60 absolute"
        @click="closeModal"
      ></div>
      <div
        class="
          flex flex-col
          max-w-lg
          w-11/12
          mx-auto
          rounded-lg
          border border-lighGrey
          z-20
          overflow-scroll
          h-5/6
          overflow-x-hidden
        "
      >
        <!-- Modal Body -->
        <div class="flex flex-col p-4 bg-white">
          <!-- Title -->
          <h2 class="font-semibold text-teal text-2xl self-center pb-2">
            Personal Information
          </h2>
          <!-- Icon -->
          <div class="relative">
            <a href="#" class="block cursor-default">
              <img
                alt="Profile"
                src="../../../../assets/1.png"
                ref="img"
                class="mx-auto object-cover rounded-full w-16 h-16"
              />
              <div
                class="
                  absolute
                  bottom-0
                  text-center
                  w-full
                  pb-3
                  text-white
                  font-bold
                "
              >
                <label class="cursor-pointer" for="imageUpload">EDIT</label>
                <input
                  id="imageUpload"
                  type="file"
                  ref="file"
                  class="hidden"
                  @change="uploadFile"
                />
              </div>
            </a>
          </div>
          <!-- First Name / Middle Name -->
          <div class="md:flex my-1 md:ml-10">
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="first_name"
                >First Name*</label
              >
              <input
                type="text"
                name="first_name"
                placeholder="Jon"
                required
                v-model="first_name"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="middle_name"
                >Middle Name</label
              >
              <input
                type="text"
                name="middle_name"
                placeholder="M"
                v-model="middle_name"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
          </div>
          <!-- Last Name / Maiden Name -->
          <div class="md:flex my-1 md:ml-10">
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="last_name"
                >Last Name*</label
              >
              <input
                type="text"
                name="last_name"
                placeholder="Doe"
                required
                v-model="last_name"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="maiden_name"
                >Maiden Name</label
              >
              <input
                type="text"
                name="maiden_name"
                placeholder="Snith"
                v-model="maiden_name"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
          </div>
          <!-- DOB / Gender -->
          <div class="md:flex my-1 md:ml-10">
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="dob"
                >Date of Birth*</label
              >
              <input
                placeholder="MM/DD/YYYY"
                onfocus="(this.type = 'date')"
                required
                type="text"
                name="dob"
                v-model="dob"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="gender"
                >Gender*</label
              >
              <select
                name="gender"
                required
                id="gender-select"
                v-model="gender"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              >
                <option value="">Choose an option</option>
                <option selected value="Female">Female</option>
                <option value="Male">Male</option>
                <option value="Non-Binary">Non-Binary</option>
                <option value="Transgender">Transgender</option>
                <option value="Intersex">Intersex</option>
                <option value="I prefer not to say">I prefer not to say</option>
              </select>
            </div>
          </div>
          <!-- Professional / Personal Email -->
          <div class="md:flex my-1 md:ml-10">
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="professional_email"
                >Professional Email</label
              >
              <input
                type="email"
                name="professional_email"
                placeholder="email@nhs.net"
                v-model="professional_email"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="email"
                >Personal Email*</label
              >
              <input
                type="email"
                name="email"
                disabled
                :placeholder="email"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
          </div>
          <!-- Home / Mobile Telephone -->
          <div class="md:flex my-1 md:ml-10">
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="home_phone"
                >Home Telephone Number</label
              >
              <input
                type="text"
                name="home_phone"
                placeholder="+44 123456789"
                v-model="home_phone"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="mobile_phone"
                >Mobile Telephone Number*</label
              >
              <input
                type="text"
                name="mobile_phone"
                placeholder="+44 123456789"
                required
                v-model="mobile_phone"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
          </div>
          <!-- Work Telephone / NHS Number -->
          <div class="md:flex my-1 md:ml-10">
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="work_phone"
                >Work Telephone Number</label
              >
              <input
                type="text"
                name="work_phone"
                placeholder="+44 123456789"
                v-model="work_phone"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="nhs_number"
                >NHS Number</label
              >
              <input
                type="text"
                name="nhs_number"
                placeholder="+44 123456789"
                v-model="nhs_number"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
          </div>
          <!-- NHS Card / Position -->
          <div class="md:flex my-1 md:ml-10">
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="nhs_card"
                >NHS Card*</label
              >
              <select
                required
                name="nhs_card"
                id="nhsCard-select"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
                v-model="nhs_card"
              >
                <option selected value="" disabled>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Pending">Pending</option>
              </select>
            </div>
          </div>
          <!-- Line 1 / Line 2 -->
          <div class="md:flex my-1 md:ml-10">
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="address_line_1"
                >Line 1*</label
              >
              <input
                type="text"
                name="address_line_1"
                placeholder="1, Street Name"
                required
                v-model="address_line_1"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex pb-1"
                for="address_line_2"
                >Line 2*</label
              >
              <input
                type="text"
                name="address_line_2"
                placeholder="-"
                required
                v-model="address_line_2"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
          </div>
          <!-- City / County -->
          <div class="md:flex my-1 md:ml-10">
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="city"
                >City*</label
              >
              <input
                type="text"
                name="city"
                placeholder="City Name"
                required
                v-model="city"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="county"
                >County*</label
              >
              <input
                type="text"
                name="county"
                placeholder="-"
                required
                v-model="county"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
          </div>
          <!-- Country / Post Code -->
          <div class="md:flex my-1 md:ml-10">
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="city"
                >Country*</label
              >
              <input
                type="text"
                name="country"
                placeholder="UK"
                required
                v-model="country"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex pb-1" for="zip_code"
                >Post Code*</label
              >
              <input
                type="text"
                name="zip_code"
                placeholder="LE12 R59"
                required
                v-model="zip_code"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
          </div>
        </div>
        <div
          class="
            flex flex-row
            items-center
            justify-end
            p-5
            bg-white
            border-t border-lightGrey
            rounded-bl-lg rounded-br-lg
          "
        >
          <p
            class="
              px-11
              py-2
              text-white
              font-semibold
              bg-orange
              rounded-full
              cursor-pointer
            "
            @click="closeModal"
          >
            Cancel
          </p>
          <base-button :animateButton="animateButton"> {{ animateButton ? '' : 'Post' }} </base-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    animateButton: {
      type: Boolean,
      required: true,
    },
    profileData: {
      type: Object,
      required: true,
    },
  },
  emits: ["close-modal", "submit-modal"],
  created() {
    if (this.profileData && this.profileData !== null) {
      (this.profile_image.url = this.profileData.profile.profile_image),
        (this.first_name = this.profileData.profile.first_name),
        (this.middle_name = this.profileData.profile.middle_name),
        (this.last_name = this.profileData.profile.last_name),
        (this.maiden_name = this.profileData.profile.maiden_name),
        (this.dob = this.profileData.profile.dob),
        (this.gender = this.profileData.profile.gender),
        (this.professional_email = this.profileData.profile.professional_email),
        (this.originalProfessionalEmail =
          this.profileData.profile.professional_email),
        (this.email = this.profileData.email),
        (this.home_phone = this.profileData.profile.home_phone),
        (this.mobile_phone = this.profileData.profile.mobile_phone),
        (this.work_phone = this.profileData.profile.work_phone),
        (this.nhs_number = this.profileData.profile.nhs_number),
        (this.nhs_card = this.profileData.profile.nhs_card),
        (this.firstRoleSelect = null),
        (this.address_line_1 = this.profileData.profile.address_line_1),
        (this.address_line_2 = this.profileData.profile.address_line_2),
        (this.city = this.profileData.profile.city),
        (this.county = this.profileData.profile.county),
        (this.country = this.profileData.profile.country),
        (this.zip_code = this.profileData.profile.zip_code);
    }
  },
  data() {
    return {
      originalProfessionalEmail: "",
      profile_image: { url: null, file: null },
      first_name: "",
      middle_name: "",
      last_name: "",
      maiden_name: "",
      dob: "",
      gender: "",
      professional_email: "",
      email: "",
      home_phone: "",
      mobile_phone: "",
      work_phone: "",
      nhs_number: "",
      nhs_card: "",
      firstRoleSelect: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      county: "",
      country: "",
      zip_code: "",
      fileSizeOK: false,
      imageError: false,
    };
  },
  methods: {
    setAltImg() {
      this.imageError = true;
    },

    uploadFile() {
      this.profile_image.file = this.$refs.file.files[0];
      this.profile_image.url = URL.createObjectURL(this.$refs.file.files[0]);
      if (this.profile_image.file.size > 1024 * 1024) {
        this.profile_image = null;
        return (this.fileSizeOK = true);
      } else {
        this.imageError = false;
        return;
      }
    },
    closeModal() {
      this.$emit("close-modal");
    },
    submitModal() {
      const payload = {
        profile: this.profileData.profile.id,
        first_name: this.first_name,
        middle_name: this.middle_name,
        last_name: this.last_name,
        maiden_name: this.maiden_name,
        dob: this.dob,
        gender: this.gender,
        home_phone: this.home_phone,
        mobile_phone: this.mobile_phone,
        work_phone: this.work_phone,
        nhs_number: this.nhs_number,
        nhs_card: this.nhs_card,
        // primary_role: this.primary_role,
        // firstRoleSelect: this.firstRoleSelect,
        address_line_1: this.address_line_1,
        address_line_2: this.address_line_2,
        city: this.city,
        county: this.county,
        country: this.country,
        zip_code: this.zip_code,
      };
      if (this.originalProfessionalEmail != this.professional_email) {
        payload.professional_email = this.professional_email;
      }
      // if (this.profile_image.file) {
      //   payload.profile_image = this.profile_image.file;
      // }
      this.$emit("submit-modal", "profile/userUpdate", payload);
    },
  },
};
</script>

<style scoped>
input[type="radio"] + label span {
  transition: background-color 0.2s, transform 0.2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
}

input[type="radio"]:checked + label span {
  background-color: #3490dc;
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked + label {
  color: #3490dc;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hideScroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
