<template>
  <form @submit.prevent="submitModal">
    <div class="
          flex
          justify-center
          h-screen
          w-screen
          items-center
          z-20
          absolute
          top-0
          left-0
          -translate-x-1/2
        ">
      <div class="h-screen w-screen bg-grey bg-opacity-60 absolute" @click="closeModal"></div>
      <div class="
            flex flex-col
            min-w-lg
            max-w-6xl
            mx-auto
            rounded-lg
            border border-lighGrey
            z-20
            overflow-scroll
            max-h-5/6
            overflow-x-hidden
          ">
        <!-- Modal Body -->
        <div class="flex flex-col p-4 bg-white">
          <!-- Title -->
          <h2 class="font-semibold text-teal text-2xl self-center pb-2">
            Education Information
          </h2>
          <div class="border border-grey rounded-lg p-3 my-1">
            <!-- Divider -->
            <div>
              <h3 class="
                    w-4/5
                    mt-3
                    mx-auto
                    overflow-hidden
                    text-center text-orange
                  ">
                Education
              </h3>
              <div v-if="getEducationInformation" class="flex justify-end">
                <p class="
                      w-20
                      py-1
                      mx-1
                      my-1
                      md:mr-5
                      text-center text-white
                      bg-red
                      rounded-full
                      cursor-pointer
                    " @click.prevent="deleteOption()">
                  Delete
                </p>
              </div>
            </div>
            <!-- Institution / Subject -->
            <div class="sm:flex my-1 md:ml-10">
              <!-- Institution -->
              <div class="w-full">
                <label class="font-semibold text-grey flex pb-1" for="institution">Institution</label>
                <div class="my-1">
                  <div class="sm:my-1">
                    <input type="text" v-model="institution" name="institution" class="
                          rounded-lg
                          border
                          bg-lightGrey
                          w-full
                          sm:w-11/12
                          h-10
                          pl-2
                        " :class="
                          v$.institution.$error === true ? 'border-red' : 'border-darkGrey'
                        " />
                  </div>
                </div>
              </div>
              <!-- Subject -->
              <div class="w-full">
                <label class="font-semibold text-grey flex pb-1" for="subject">Subject</label>
                <div class="my-1">
                  <div class="sm:flex sm:justify-between sm:my-1">
                    <input type="text" name="subject" v-model="subject" class="
                          rounded-lg
                          border
                          bg-lightGrey
                          w-full
                          sm:w-11/12
                          h-10
                          pl-2
                        " :class="
                          v$.subject.$error === true ? 'border-red' : 'border-darkGrey'
                        "/>
                  </div>
                </div>
              </div>
            </div>
            <!-- Start Date / Completion Date -->
            <div class="sm:flex my-1 md:ml-10">
              <!-- Start Date -->
              <div class="w-full">
                <label class="font-semibold text-grey flex pb-1" for="startDate">Start Date</label>
                <div class="my-1">
                  <div class="sm:my-1">
                    <input placeholder="MM/DD/YYYY" onfocus="(this.type = 'date')" type="text" v-model="startDate"
                      name="startDate" class="
                          rounded-lg
                          border
                          bg-lightGrey
                          w-full
                          sm:w-11/12
                          h-10
                          pl-2
                        " :class="
                          v$.startDate.$error === true ? 'border-red' : 'border-darkGrey'
                        "/>
                  </div>
                </div>
              </div>
              <!-- Completion Date -->
              <div class="w-full">
                <label class="font-semibold text-grey flex pb-1" for="completionDate">Completion Date</label>
                <div class="my-1">
                  <div class="sm:flex sm:justify-between sm:my-1">
                    <input placeholder="MM/DD/YYYY" onfocus="(this.type = 'date')" type="text" name="completionDate"
                      v-model="completionDate" class="
                          rounded-lg
                          border
                          bg-lightGrey
                          w-full
                          sm:w-11/12
                          h-10
                          pl-2
                        " :class="
                          v$.completionDate.$error === true ? 'border-red' : 'border-darkGrey'
                        "/>
                  </div>
                </div>
              </div>
            </div>
            <!-- Degree / Grade -->
            <div class="sm:flex my-1 md:ml-10">
              <!-- Degree -->
              <div class="w-full">
                <label class="font-semibold text-grey flex pb-1" for="degree">Degree</label>
                <div class="my-1">
                  <div class="sm:my-1">
                    <input type="text" v-model="degree" name="degree" class="
                          rounded-lg
                          border
                          bg-lightGrey
                          w-full
                          sm:w-11/12
                          h-10
                          pl-2
                        " :class="
                          v$.degree.$error === true ? 'border-red' : 'border-darkGrey'
                        "/>
                  </div>
                </div>
              </div>
              <!-- Grade -->
              <div class="w-full">
                <label class="font-semibold text-grey flex pb-1" for="grade">Grade</label>
                <div class="my-1">
                  <div class="sm:flex sm:justify-between sm:my-1">
                    <input type="text" name="grade" v-model="grade" class="
                          rounded-lg
                          border
                          bg-lightGrey
                          w-full
                          sm:w-11/12
                          h-10
                          pl-2
                        " :class="
                          v$.grade.$error === true ? 'border-red' : 'border-darkGrey'
                        "/>
                  </div>
                </div>
              </div>
            </div>
            <!-- Certificate Attachment -->
            <div class="
                  flex flex-col
                  items-center
                  sm:flex-row
                  my-1
                  sm:ml-2
                  md:ml-10
                ">
              <div class="w-full">
                <label class="font-semibold text-grey flex pb-1" for="certificate">Certificate</label>
                <div class="relative w-full">
                  <input type="file" ref="certificateFile" name="certificate"
                    class="relative text-right opacity-0 z-20 w-11.5/12 h-10" @change="uploadFile" />
                  <div class="absolute top-0 left-0 z-10 w-11.5/12 h-10">
                    <input class="
                          rounded-lg
                          border
                          bg-lightGrey
                          pl-2
                          w-full
                          h-10
                        " :class="[
                          certificateFileSizeOK || v$.certificate.$error === true
                            ? 'border-red'
                            : 'border-darkGrey',
                        ]" :placeholder="
  checkFile(certificate, certificateFileSizeOK)
" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Cancel / Post Modal -->
        <div class="
              flex flex-row
              items-center
              justify-end
              p-5
              bg-white
              border-t border-lightGrey
              rounded-bl-lg rounded-br-lg
            ">
          <!-- Cancel Modal -->
          <p class="
                px-11
                py-2
                text-white
                font-semibold
                bg-orange
                rounded-full
                cursor-pointer
              " @click="closeModal">
            Cancel
          </p>
          <!-- Post Modal -->
          <base-button :animateButton="animateButton"> {{ animateButton ? '' : 'Post' }} </base-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  props: {
    animateButton: {
      type: Boolean,
      required: true,
    },
    getEducationInformation: {
      type: Object,
    },
    userID: {
      type: [Number, String],
    },
  },
  emits: ["close-modal", "submit-modal", "delete-item", "submit-modal-create"],
  created() {
    if (this.getEducationInformation) {
      this.educationId = this.getEducationInformation.id;
      this.institution = this.getEducationInformation.institution;
      (this.subject = this.getEducationInformation.subject),
        (this.startDate = this.convertDate(
          this.getEducationInformation.start_date
        )),
        (this.completionDate = this.convertDate(
          this.getEducationInformation.completion_date
        )),
        (this.degree = this.getEducationInformation.degree),
        (this.grade = this.getEducationInformation.grade),
        (this.certificate = this.getEducationInformation.certificate);
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      educationId: null,
      institution: null,
      subject: null,
      startDate: null,
      completionDate: null,
      degree: null,
      grade: null,
      certificate: null,
      certificateFileSizeOK: false,
    };
  },
  validations() {
    return {
      institution: { required },
      subject: { required },
      startDate: { required },
      completionDate: { required },
      degree: { required },
      grade: { required },
      certificate: { required },
    };
  },
  methods: {
    convertDate(data) {
      return moment(data).format("DD-MM-YYYY");
    },
    convertDateBACK(data) {
      const newDate = moment(data, "YYYY-MM-DD").toDate();
      return moment(newDate).format("YYYY-MM-DD");
    },
    uploadFile() {
      this.certificate = this.$refs.certificateFile.files[0];
      if (this.certificate.size > 1024 * 1024) {
        this.certificate = {};
        return (this.certificateFileSizeOK = true);
      } else {
        return (this.certificateFileSizeOK = false);
      }
    },
    checkFile(attachment, sizeCheck) {
      if (typeof attachment === "object" && attachment !== null) {
        if (sizeCheck) {
          return "Click here to attach a file (< 1MB)...";
        } else {
          return attachment.name;
        }
      } else if (attachment !== null) {
        const url = new URL(attachment);
        const newerURL = url.pathname.split("/");
        let lastElement = newerURL[newerURL.length - 1];
        let arr = lastElement.split(".");
        arr.shift();
        lastElement = arr.join(".");
        return lastElement;
      }
      return;
    },
    deleteOption() {
      const payload = {
        education: this.educationId,
      };
      this.$emit("delete-item", payload);
    },
    closeModal() {
      this.$emit("close-modal");
    },
    submitModal() {
      this.v$.$validate();
      if(this.v$.$error){
        return;
      }
      const payload = new FormData();
      payload.append("education", this.educationId);
      payload.append("institution", this.institution);
      payload.append("subject", this.subject);
      payload.append("start_date", this.convertDateBACK(this.startDate));
      payload.append(
        "completion_date",
        this.convertDateBACK(this.completionDate)
      );
      payload.append("degree", this.degree);
      payload.append("grade", this.grade);
      if (typeof this.certificate === "object") {
        payload.append("certificate", this.certificate);
      }

      if (this.getEducationInformation) {
        this.$emit("submit-modal", "profile/updateEducation", payload);
      } else {
        if (this.userID) {
          payload.append("user", this.userID);
        } else {
          payload.append("user", this.$store.getters["profile/getProfile"].id);
        }

        payload.is_current = 0;
        this.$emit("submit-modal-create", "profile/createEducation", payload);
      }
    },
  },
};
</script>

<style scoped>
h3:before,
h3:after {
  content: "";
  color: #000;
  display: inline-block;
  width: 50%;
  margin: 0 0.5em 0 -55%;
  vertical-align: middle;
  border-bottom: 1px solid;
}

h3:after {
  margin: 0 -55% 0 0.5em;
}

input[type="radio"]+label span {
  transition: background-color 0.2s, transform 0.2s;
}

input[type="radio"]+label span:hover,
input[type="radio"]+label:hover span {
  transform: scale(1.2);
}

input[type="radio"]:checked+label span {
  background-color: #3490dc;
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked+label {
  color: #3490dc;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
</style>

