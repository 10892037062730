<template>
  <!-- Miscellaneous Information -->
  <div>
    <div class="border-2 border-grey rounded-xl py-2 w-full mt-5 text-darkGrey">
      <div class="flex justify-between mx-3">
        <h2 class="text-teal">Employment Information</h2>
        <div v-if="!hideEditButton" class="flex items-start cursor-pointer">
          <span class="material-icons" @click="miscellaneousInformationModal()">
            edit
          </span>
        </div>
      </div>
      <template v-if="getMiscData">
        <div class="flex m-2 ml-3">
          <p class="flex-1">Job Specification:</p>
          <!-- <input class="w-2/5" type="text" value="Lorem Ipsum" /> -->
          <p v-if="getMiscData && getMiscData.job_specification" class="w-2/5">
            {{ getMiscData.job_specification.toUpperCase() }}
          </p>
          <p v-else class="w-2/5">N/A</p>
        </div>
        <div class="flex m-2 ml-3">
          <p class="flex-1">Interview Notes:</p>
          <p class="w-2/5" v-if="getMiscData && getMiscData.interview_notes">
            {{ getMiscData.interview_notes.toUpperCase() }}
          </p>
          <p v-else class="w-2/5">N/A</p>
        </div>
        <div class="flex m-2 ml-3">
          <p class="flex-1">Offer Letter/Email:</p>
          <a
            v-if="getMiscData && getMiscData.offer_letter_email"
            class="w-2/5 underline cursor-pointer"
            :href="getMiscData.offer_letter_email"
            target="_blank"
            ><span class="material-icons text-sm"> description </span>
            Offer Letter
          </a>
          <p v-else class="w-2/5">N/A</p>
        </div>
        <div class="flex m-2 ml-3">
          <p class="flex-1">Job Advertisement:</p>
          <a
            v-if="getMiscData && getMiscData.job_advertisement"
            class="w-2/5 underline cursor-pointer"
            :href="getMiscData.job_advertisement"
            target="_blank"
            ><span class="material-icons text-sm"> description </span>
            Job Advertisement
          </a>
          <p v-else class="w-2/5">N/A</p>
        </div>
        <div class="flex m-2 ml-3">
          <p class="flex-1">Health Questionnaire:</p>
          <a
            v-if="getMiscData && getMiscData.health_questionnaire"
            class="w-2/5 underline cursor-pointer"
            :href="getMiscData.health_questionnaire"
            target="_blank"
            ><span class="material-icons text-sm"> description </span>
            Health Questionnaire
          </a>
          <p v-else class="w-2/5">N/A</p>
        </div>
        <div class="flex m-2 ml-3">
          <p class="flex-1">Annual Declaration:</p>
          <a
            v-if="getMiscData && getMiscData.annual_declaration"
            class="w-2/5 underline cursor-pointer"
            :href="getMiscData.annual_declaration"
            target="_blank"
            ><span class="material-icons text-sm"> description </span>
            Annual Declaration
          </a>
          <p v-else class="w-2/5">N/A</p>
        </div>
        <div class="flex m-2 ml-3">
          <p class="flex-1">Employee Confidentiality Agreement:</p>
          <a
            v-if="getMiscData && getMiscData.employee_confidentiality_agreement"
            class="w-2/5 underline cursor-pointer"
            :href="getMiscData.employee_confidentiality_agreement"
            target="_blank"
            ><span class="material-icons text-sm"> description </span>
            Employee Confidentiality
          </a>
          <p v-else class="w-2/5">N/A</p>
        </div>
        <div class="flex m-2 ml-3">
          <p class="flex-1">Employee Privacy Notice:</p>
          <a
            v-if="getMiscData && getMiscData.employee_privacy_notice"
            class="w-2/5 underline cursor-pointer"
            :href="getMiscData.employee_privacy_notice"
            target="_blank"
            ><span class="material-icons text-sm"> description </span>
            Employee Privacy Notice
          </a>
          <p v-else class="w-2/5">N/A</p>
        </div>
        <div class="flex m-2 ml-3">
          <p class="flex-1">Locker Key Agreement:</p>
          <a
            v-if="getMiscData && getMiscData.locker_key_agreement"
            class="w-2/5 underline cursor-pointer"
            :href="getMiscData.locker_key_agreement"
            target="_blank"
            ><span class="material-icons text-sm"> description </span>
            Locker Key Agreement
          </a>
          <p v-else class="w-2/5">N/A</p>
        </div>
        <div class="flex m-2 ml-3">
          <p class="flex-1">Locker Key Assigned:</p>
          <p class="w-2/5">
            <span v-if="getMiscData.is_locker_key_assigned">Yes</span>
            <span v-else>No</span>
          </p>
        </div>
        <div class="flex m-2 ml-3">
          <p class="flex-1">Equipment Provided Policy:</p>
          <a
            v-if="getMiscData && getMiscData.equipment_provided_policy"
            class="w-2/5 underline cursor-pointer"
            :href="getMiscData.equipment_provided_policy"
            target="_blank"
            ><span class="material-icons text-sm"> description </span>
            Provided Equipment Policy
          </a>
          <p v-else class="w-2/5">N/A</p>
        </div>
        <div class="flex m-2 ml-3">
          <p class="flex-1">CV:</p>
          <a
            v-if="getMiscData && getMiscData.resume"
            class="w-2/5 underline cursor-pointer"
            :href="getMiscData.resume"
            target="_blank"
            ><span class="material-icons text-sm"> description </span>
            Resume
          </a>
          <p v-else class="w-2/5">N/A</p>
        </div>
        <div class="flex m-2 ml-3">
          <p class="flex-1">Proof Of Address:</p>
          <a
            v-if="getMiscData && getMiscData.proof_of_address"
            class="w-2/5 underline cursor-pointer"
            :href="getMiscData.proof_of_address"
            target="_blank"
            ><span class="material-icons text-sm"> description </span>
            Proof Of Address
          </a>
          <p v-else class="w-2/5">N/A</p>
        </div>
      </template>
      <div v-else>
        <no-data-found />
      </div>
    </div>
  </div>
</template>

<script>
import noDataFound from "@/components/ui/baseComponents/noDataFound.vue";

export default {
  components: { noDataFound },

  emits: ["modalTrigger"],
  props: {
    getMiscData: {
      type: Object,
      // required: true,
    },
    hideEditButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMiscellaneousInformationModal: false,
    };
  },
  methods: {
    miscellaneousInformationModal() {
      this.showMiscellaneousInformationModal =
        !this.showMiscellaneousInformationModal;
      this.$emit("modalTrigger", this.showMiscellaneousInformationModal);
    },
  },
};
</script>
