<template>
  <form @submit.prevent="submitModal">
    <div
      class="
        flex
        justify-center
        h-screen
        w-screen
        items-center
        z-20
        absolute
        top-0
        left-0
        -translate-x-1/2
      "
    >
      <div
        class="h-screen w-screen bg-grey bg-opacity-60 absolute"
        @click="closeModal"
      ></div>
      <div
        class="
          hideScroll
          flex flex-col
          min-w-lg
          max-w-6xl
          mx-auto
          rounded-lg
          border border-lighGrey
          z-20
          overflow-scroll
          max-h-5/6
          overflow-x-hidden
        "
      >
        <!-- Modal Body -->
        <div class="flex flex-col p-4 bg-white">
          <!-- Title -->
          <h2 class="font-semibold text-teal text-2xl self-center pb-2">
            References
          </h2>
          <div class="border border-grey rounded-lg p-3 my-1">
            <!-- Divider -->
            <div>
              <h3
                class="
                  w-4/5
                  mt-3
                  mx-auto
                  overflow-hidden
                  text-center text-orange
                "
              >
                Reference
              </h3>
              <div v-if="getReferencesData" class="flex justify-end">
                <p
                  class="
                    w-20
                    py-1
                    mx-1
                    my-1
                    md:mr-5
                    text-center text-white
                    bg-red
                    rounded-full
                    cursor-pointer
                  "
                  @click.prevent="deleteOption()"
                >
                  Delete
                </p>
              </div>
            </div>
            <!-- Reference Type -->
            <div class="flex sm:flex-col flex-row my-1 sm:ml-2 md:ml-10">
              <div class="w-full">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="referenceType"
                  >Reference Type*</label
                >
                <div class="flex justify-between">
                  <div class="flex flex-col sm:flex-row w-full md:w-11/12">
                    <div class="">
                      <label class="inline-flex items-center">
                        <input
                          type="radio"
                          value="current_employer"
                          v-model="referenceType"
                          id="current_employer"
                        />
                        <span class="ml-2">Current Employer</span>
                      </label>
                    </div>
                    <div class="md:ml-5">
                      <label class="inline-flex items-center">
                        <input
                          type="radio"
                          value="previous_employer"
                          v-model="referenceType"
                          id="previous_employer"
                        />
                        <span class="ml-2">Previous Employer</span>
                      </label>
                    </div>
                    <div class="md:ml-5">
                      <label class="inline-flex items-center">
                        <input
                          type="radio"
                          value="education_reference"
                          v-model="referenceType"
                          id="education_reference"
                        />
                        <span class="ml-2">Education</span>
                      </label>
                    </div>
                    <div class="md:ml-5">
                      <label class="inline-flex items-center">
                        <input
                          type="radio"
                          value="personal_character_reference"
                          v-model="referenceType"
                          id="personal_character_reference"
                        />
                        <span class="ml-2">Personal/Character</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Referee Name / Company/Employer Name  -->
            <div class="md:flex my-1 md:ml-10">
              <!-- Referee Name -->
              <div class="w-full md:w-1/2">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="refereeName"
                  >Referee Name*</label
                >
                <div class="my-1">
                  <div class="sm:my-1">
                    <input
                      required
                      type="text"
                      name="refereeName"
                      v-model="refereeName"
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-10
                        pl-2
                      "
                    />
                  </div>
                </div>
              </div>
              <!-- Company/Employer Name  -->
              <div class="w-full md:w-1/2">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="employerCompanyName"
                  >Company/Employer Name*</label
                >
                <div class="my-1">
                  <div class="sm:my-1">
                    <input
                      required
                      type="text"
                      v-model="employerCompanyName"
                      name="employerCompanyName"
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-10
                        pl-2
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Relationship / Referee job title -->
            <div class="sm:flex my-1 md:ml-10">
              <!-- Relationship -->
              <div class="w-full">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="relationship"
                  >Relationship*</label
                >
                <div class="my-1">
                  <div class="sm:my-1">
                    <input
                      required
                      type="text"
                      v-model="relationship"
                      name="relationship"
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-10
                        pl-2
                      "
                    />
                  </div>
                </div>
              </div>
              <!-- Referee job title -->
              <div class="w-full">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="refereeJobTitle"
                  >Referee job title*</label
                >
                <div class="my-1">
                  <div class="sm:flex sm:justify-between sm:my-1">
                    <input
                      required
                      type="text"
                      name="refereeJobTitle"
                      v-model="refereeJobTitle"
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-10
                        pl-2
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Telephone / Referee email address -->
            <div class="sm:flex my-1 md:ml-10">
              <!-- Telephone -->
              <div class="w-full">
                <label class="font-semibold text-grey flex pb-1" for="telephone"
                  >Telephone*</label
                >
                <div class="my-1">
                  <div class="sm:my-1">
                    <input
                      required
                      type="number"
                      v-model="telephone"
                      name="telephone"
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-10
                        pl-2
                      "
                    />
                  </div>
                </div>
              </div>
              <!-- Referee email address -->
              <div class="w-full">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="refereeEmailAddress"
                  >Referee email address*</label
                >
                <div class="my-1">
                  <div class="sm:flex sm:justify-between sm:my-1">
                    <input
                      required
                      type="email"
                      name="refereeEmailAddress"
                      v-model="refereeEmailAddress"
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-10
                        pl-2
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Start Date / End Date -->
            <div class="sm:flex my-1 md:ml-10">
              <!-- Start Date -->
              <div class="w-full">
                <label class="font-semibold text-grey flex pb-1" for="startDate"
                  >Start Date*</label
                >
                <div class="my-1">
                  <div class="sm:my-1">
                    <input
                      required
                      placeholder="MM/DD/YYYY"
                      onfocus="(this.type = 'date')"
                      type="text"
                      v-model="startDate"
                      name="startDate"
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-10
                        pl-2
                      "
                    />
                  </div>
                </div>
              </div>
              <!-- End Date -->
              <div class="w-full">
                <label class="font-semibold text-grey flex pb-1" for="endDate"
                  >End Date*</label
                >
                <div class="my-1">
                  <div class="sm:flex sm:justify-between sm:my-1">
                    <input
                      required
                      placeholder="MM/DD/YYYY"
                      onfocus="(this.type = 'date')"
                      type="text"
                      name="endDate"
                      v-model="endDate"
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-10
                        pl-2
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Period of notice / Can be contacted -->
            <div class="sm:flex my-1 md:ml-10">
              <!-- Can be contacted -->
              <div class="w-full md:w-1/2">
                <label class="font-semibold text-grey flex mt-1 pb-1" for=""
                  >Can be contacted?*</label
                >
                <div class="flex w-full md:w-11/12">
                  <div class="">
                    <label class="inline-flex items-center">
                      <input
                        required
                        type="radio"
                        v-model="canBeContacted"
                        name="canBeContacted"
                        :value="1"
                      />
                      <span class="ml-2">Yes</span>
                    </label>
                  </div>
                  <div class="ml-5">
                    <label class="inline-flex items-center">
                      <input
                        type="radio"
                        v-model="canBeContacted"
                        name="canBeContacted"
                        :value="0"
                      />
                      <span class="ml-2">No</span>
                    </label>
                  </div>
                </div>
              </div>
              <!-- Period of notice -->
              <div class="w-full md:w-1/2">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="referenceDocument"
                  >Reference Document</label
                >
                <div class="relative w-full">
                  <input
                    ref="referenceFile"
                    type="file"
                    name="referenceDocument"
                    class="relative text-right opacity-0 z-20 w-11.5/12 h-10"
                    @change="uploadFile"
                  />
                  <div class="absolute top-0 left-0 z-10 w-11.5/12 h-10">
                    <input
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        pl-2
                        w-full
                        h-10
                      "
                      :class="[
                        referenceDocumentFileSizeOK
                          ? 'border-red'
                          : 'border-darkGrey',
                      ]"
                      :placeholder="
                        checkFile(
                          referenceDocument,
                          referenceDocumentFileSizeOK
                        )
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Cancel / Post Modal -->
        <div
          class="
            flex flex-row
            items-center
            justify-end
            p-5
            bg-white
            border-t border-lightGrey
            rounded-bl-lg rounded-br-lg
          "
        >
          <!-- Cancel Modal -->
          <p
            class="
              px-11
              py-2
              text-white
              font-semibold
              bg-orange
              rounded-full
              cursor-pointer
            "
            @click="closeModal"
          >
            Cancel
          </p>
          <!-- Post Modal -->
          <base-button :animateButton="animateButton"> Post </base-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import moment from "moment";
export default {
  props: {
    animateButton: {
      type: Boolean,
      required: true,
    },
    getReferencesData: {
      type: Object,
    },
    userID: {
      type: [Number, String],
    },
  },
  emits: ["close-modal", "submit-modal", "delete-item"],
  created() {
    if (this.getReferencesData) {
      this.referenceId = this.getReferencesData.id;
      this.referenceType = this.getReferencesData.reference_type;
      (this.refereeName = this.getReferencesData.referee_name),
        (this.employerCompanyName = this.getReferencesData.company_name),
        (this.relationship = this.getReferencesData.relationship),
        (this.refereeJobTitle = this.getReferencesData.referee_job_title),
        (this.telephone = this.getReferencesData.phone_number),
        (this.refereeEmailAddress = this.getReferencesData.referee_email),
        (this.startDate = this.convertDate(this.getReferencesData.start_date)),
        (this.endDate = this.convertDate(this.getReferencesData.end_date)),
        (this.canBeContacted = this.getReferencesData.can_contact_referee),
        (this.referenceDocument = this.getReferencesData.reference_document);
    }
  },
  data() {
    return {
      referenceId: null,
      referenceType: null,
      refereeName: null,
      employerCompanyName: null,
      relationship: null,
      refereeJobTitle: null,
      telephone: null,
      refereeEmailAddress: null,
      startDate: null,
      endDate: null,
      canBeContacted: null,
      referenceDocument: null,
      referenceDocumentFileSizeOK: null,
    };
  },
  methods: {
    convertDate(data) {
      return moment(data).format("DD-MM-YYYY");
    },
    convertDateBACK(data) {
      const newDate = moment(data, "YYYY-MM-DD").toDate();
      return moment(newDate).format("YYYY-MM-DD");
    },
    deleteOption() {
      const payload = {
        reference: this.referenceId,
      };
      this.$emit("delete-item", payload);
    },
    uploadFile() {
      this.referenceDocument = this.$refs.referenceFile.files[0];
      if (this.referenceDocument.size > 1024 * 1024) {
        this.referenceDocument = null;
        return (this.referenceDocumentFileSizeOK = true);
      } else {
        return;
      }
    },
    checkFile(attachment, sizeCheck) {
      if (typeof attachment === "object" && attachment !== null) {
        if (sizeCheck) {
          return "Click here to attach a file (< 1MB)...";
        } else {
          return attachment.name;
        }
      } else if (attachment !== null) {
        const url = new URL(attachment);
        const newerURL = url.pathname.split("/");
        let lastElement = newerURL[newerURL.length - 1];
        let arr = lastElement.split(".");
        arr.shift();
        lastElement = arr.join(".");
        return lastElement;
      }
      return;
    },

    closeModal() {
      this.$emit("close-modal");
    },
    submitModal() {
      const payload = new FormData();
      payload.append("reference", this.referenceId);
      payload.append("reference_type", this.referenceType);
      payload.append("referee_name", this.refereeName);
      payload.append("company_name", this.employerCompanyName);
      payload.append("relationship", this.relationship);
      payload.append("referee_job_title", this.refereeJobTitle);
      payload.append("phone_number", this.telephone);
      payload.append("referee_email", this.refereeEmailAddress);
      payload.append("start_date", this.convertDateBACK(this.startDate));
      payload.append("end_date", this.convertDateBACK(this.endDate));
      payload.append("can_contact_referee", this.canBeContacted);
      if (typeof this.referenceDocument === "object") {
        payload.append("reference_document", this.referenceDocument);
      }

      if (this.getReferencesData) {
        this.$emit("submit-modal", "profile/updateReferences", payload);
      } else {
        if (this.userID) {
          payload.append("user", this.userID);
        } else {
          payload.append("user", this.$store.getters["profile/getProfile"].id);
        }
        payload.is_current = 0;
        this.$emit("submit-modal-create", "profile/createReferences", payload);
      }
    },
  },
};
</script>

<style scoped>
h3:before,
h3:after {
  content: "";
  color: #000;
  display: inline-block;
  width: 50%;
  margin: 0 0.5em 0 -55%;
  vertical-align: middle;
  border-bottom: 1px solid;
}
h3:after {
  margin: 0 -55% 0 0.5em;
}

input[type="radio"] + label span {
  transition: background-color 0.2s, transform 0.2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
}

input[type="radio"]:checked + label span {
  background-color: #3490dc;
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked + label {
  color: #3490dc;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hideScroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
