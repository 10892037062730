<template>
  <form @submit.prevent="submitModal">
    <div
      class="
        flex
        justify-center
        h-screen
        w-screen
        items-center
        z-20
        absolute
        top-0
        left-0
        -translate-x-1/2
      "
    >
      <div
        class="h-screen w-screen bg-grey bg-opacity-60 absolute"
        @click="closeModal"
      ></div>
      <div
        class="
          hideScroll
          flex flex-col
          min-w-lg
          max-w-6xl
          mx-auto
          rounded-lg
          border border-lighGrey
          z-20
          overflow-scroll
          max-h-5/6
          overflow-x-hidden
        "
      >
        <!-- Modal Body -->
        <div class="flex flex-col p-4 bg-white">
          <!-- Title -->
          <h2 class="font-semibold text-teal text-2xl self-center pb-2">
            Employment History
          </h2>
          <div class="border border-grey rounded-lg p-3 my-1">
            <!-- Divider -->
            <div>
              <h3
                class="
                  w-4/5
                  mt-3
                  mx-auto
                  overflow-hidden
                  text-center text-orange
                "
              >
                Experience
              </h3>
            </div>
            <!-- Employer Name / Type of business / Address  -->
            <div class="md:flex my-1 md:ml-10">
              <!-- Employer Name -->
              <div class="w-full md:w-1/2">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="employerName"
                  >Employer Name*</label
                >
                <div class="my-1">
                  <div class="sm:my-1">
                    <input
                      required
                      type="text"
                      v-model="employerName"
                      name="employerName"
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-10
                        pl-2
                      "
                    />
                  </div>
                </div>
                <!-- Type of business  -->
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="typeOfBusniess"
                  >Type of business*</label
                >
                <div class="my-1">
                  <div class="sm:my-1">
                    <input
                      required
                      type="text"
                      v-model="typeOfBusniess"
                      name="typeOfBusniess"
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-10
                        pl-2
                      "
                    />
                  </div>
                </div>
              </div>
              <!-- Address -->
              <div class="w-full md:w-1/2">
                <label
                  class="font-semibold text-grey flex mt-2 md:mt-0 pb-1"
                  for="address"
                  >Address*</label
                >
                <textarea
                  required
                  name="address"
                  class="
                    rounded-lg
                    border border-darkGrey
                    bg-lightGrey
                    w-full
                    md:w-11/12
                  "
                  rows="5"
                  v-model="address"
                >
                </textarea>
              </div>
            </div>
            <!-- Telephone Number / Your Job Title -->
            <div class="sm:flex my-1 md:ml-10">
              <!-- Telephone Number -->
              <div class="w-full">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="telephoneNumber"
                  >Telephone Number*</label
                >
                <div class="my-1">
                  <div class="sm:my-1">
                    <input
                      required
                      type="number"
                      v-model="telephoneNumber"
                      name="telephoneNumber"
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-10
                        pl-2
                      "
                    />
                  </div>
                </div>
              </div>
              <!-- Your Job Title -->
              <div class="w-full">
                <label class="font-semibold text-grey flex pb-1" for="jobTitle"
                  >Your Job Title*</label
                >
                <div class="my-1">
                  <div class="sm:flex sm:justify-between sm:my-1">
                    <input
                      required
                      type="text"
                      name="jobTitle"
                      v-model="jobTitle"
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-10
                        pl-2
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Start Date / End Date -->
            <div class="sm:flex my-1 md:ml-10">
              <!-- Start Date -->
              <div class="w-full">
                <label class="font-semibold text-grey flex pb-1" for="startDate"
                  >Start Date*</label
                >
                <div class="my-1">
                  <div class="sm:my-1">
                    <input
                      required
                      placeholder="MM/DD/YYYY"
                      onfocus="(this.type = 'date')"
                      type="text"
                      v-model="startDate"
                      name="startDate"
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-10
                        pl-2
                      "
                    />
                  </div>
                </div>
              </div>
              <!-- End Date -->
              <div class="w-full">
                <label class="font-semibold text-grey flex pb-1" for="endDate"
                  >End Date*</label
                >
                <div class="my-1">
                  <div class="sm:flex sm:justify-between sm:my-1">
                    <input
                      required
                      placeholder="MM/DD/YYYY"
                      onfocus="(this.type = 'date')"
                      type="text"
                      name="endDate"
                      v-model="endDate"
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-10
                        pl-2
                      "
                    />
                  </div>
                  <!-- <div class="flex justify-end">
                    <p
                      class="w-28 mx-1 my-1 md:mr-5 text-center text-white bg-teal rounded-full cursor-pointer"
                      @click.prevent="addOption"
                    >
                      Add
                    </p>
                  </div> -->
                </div>
              </div>
            </div>
            <!-- Salary / Reporting To -->
            <div class="sm:flex my-1 md:ml-10">
              <!-- Salary -->
              <div class="w-full">
                <label class="font-semibold text-grey flex pb-1" for="salary"
                  >Salary*</label
                >
                <div class="my-1">
                  <div class="sm:my-1">
                    <input
                      required
                      type="number"
                      v-model="salary"
                      name="salary"
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-10
                        pl-2
                      "
                    />
                  </div>
                </div>
              </div>
              <!-- Reporting To -->
              <div class="w-full">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="reportingTo"
                  >Reporting To*</label
                >
                <div class="my-1">
                  <div class="sm:flex sm:justify-between sm:my-1">
                    <input
                      required
                      type="text"
                      name="reportingTo"
                      v-model="reportingTo"
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-10
                        pl-2
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Period of notice / Can be contacted -->
            <div class="sm:flex my-1 md:ml-10">
              <!-- Period of notice -->
              <div class="w-full md:w-1/2">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="periodOfNotice"
                  >Period of notice*</label
                >
                <div class="my-1">
                  <div class="sm:my-1">
                    <input
                      required
                      type="text"
                      v-model="periodOfNotice"
                      name="periodOfNotice"
                      class="
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        sm:w-11/12
                        h-10
                        pl-2
                      "
                    />
                  </div>
                </div>
              </div>
              <!-- Can be contacted -->
              <div class="w-full md:w-1/2">
                <label class="font-semibold text-grey flex mt-1 pb-1" for=""
                  >Can be contacted?*</label
                >
                <div class="flex w-full md:w-11/12">
                  <div class="">
                    <label class="inline-flex items-center">
                      <input
                        required
                        type="radio"
                        v-model="canContactReference"
                        name="
                          canContactReference
                        "
                        :value="1"
                      />
                      <span class="ml-2">Yes</span>
                    </label>
                  </div>
                  <div class="ml-5">
                    <label class="inline-flex items-center">
                      <input
                        type="radio"
                        v-model="canContactReference"
                        name="
                          canContactReference
                        "
                        :value="0"
                      />
                      <span class="ml-2">No</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!-- Reason for Leaving / Main Duties & Responsibilities -->
            <div class="sm:flex my-1 md:ml-10">
              <!-- Reason for Leaving -->
              <div class="w-full">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="reasonForLeaving"
                  >Reason for Leaving*</label
                >
                <div class="my-1">
                  <div class="sm:my-1">
                    <textarea
                      required
                      v-model="reasonForLeaving"
                      name="reasonForLeaving"
                      rows="3"
                      class="
                        pl-2
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        md:w-11/12
                      "
                    ></textarea>
                  </div>
                </div>
              </div>
              <!-- Main Duties & Responsibilities -->
              <div class="w-full">
                <label
                  class="font-semibold text-grey flex pb-1"
                  for="responsibilitiesDutiesDesc"
                  >Main Duties & Responsibilities*</label
                >
                <div class="my-1">
                  <div class="sm:flex sm:justify-between sm:my-1">
                    <textarea
                      required
                      name="responsibilitiesDutiesDesc"
                      v-model="responsibilitiesDutiesDesc"
                      rows="3"
                      class="
                        pl-2
                        rounded-lg
                        border border-darkGrey
                        bg-lightGrey
                        w-full
                        md:w-11/12
                      "
                    ></textarea>
                  </div>
                </div>
                <div
                  v-if="getExperiencesInformation"
                  class="flex justify-end bg-white pt-2"
                >
                  <p
                    class="
                      w-20
                      py-1
                      mx-1
                      my-1
                      md:mr-5
                      text-center text-white
                      bg-red
                      rounded-full
                      cursor-pointer
                    "
                    @click.prevent="deleteOption()"
                  >
                    Delete
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Cancel / Post Modal -->
        <div
          class="
            flex flex-row
            items-center
            justify-end
            p-5
            bg-white
            border-t border-lightGrey
            rounded-bl-lg rounded-br-lg
          "
        >
          <!-- Cancel Modal -->
          <p
            class="
              px-11
              py-2
              text-white
              font-semibold
              bg-orange
              rounded-full
              cursor-pointer
            "
            @click="closeModal"
          >
            Cancel
          </p>
          <!-- Post Modal -->
          <base-button :animateButton="animateButton"> Post </base-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
// import { QuillEditor } from "@vueup/vue-quill";
import moment from "moment";
export default {
  props: {
    animateButton: {
      type: Boolean,
      required: true,
    },
    getExperiencesInformation: {
      type: Object,
    },
    userID: {
      type: [Number, String],
    },
  },
  emits: ["close-modal", "submit-modal", "delete-item", "submit-modal-create"],
  created() {
    if (this.getExperiencesInformation) {
      this.employment_history = this.getExperiencesInformation.id;
      (this.employerName = this.getExperiencesInformation.employer_name),
        (this.address = this.getExperiencesInformation.address),
        (this.telephoneNumber = this.getExperiencesInformation.phone_number),
        (this.typeOfBusniess = this.getExperiencesInformation.type_of_business),
        (this.jobTitle = this.getExperiencesInformation.job_title),
        (this.startDate = this.convertDate(
          this.getExperiencesInformation.job_start_date
        )),
        (this.endDate = this.convertDate(
          this.getExperiencesInformation.job_end_date
        )),
        (this.salary = this.getExperiencesInformation.salary),
        (this.reportingTo = this.getExperiencesInformation.reporting_to),
        (this.periodOfNotice = this.getExperiencesInformation.period_of_notice),
        (this.canContactReference =
          this.getExperiencesInformation.can_contact_referee),
        (this.reasonForLeaving =
          this.getExperiencesInformation.reason_for_leaving),
        (this.responsibilitiesDutiesDesc =
          this.getExperiencesInformation.responsibilities_duties_desc);
    }
  },
  data() {
    return {
      employerName: null,
      typeOfBusniess: null,
      address: null,
      telephoneNumber: null,
      jobTitle: null,
      startDate: null,
      endDate: null,
      salary: null,
      reportingTo: null,
      periodOfNotice: null,
      canContactReference: null,
      reasonForLeaving: null,
      responsibilitiesDutiesDesc: null,
      recordedEmploymentHistory: null,
    };
  },
  methods: {
    convertDate(data) {
      return moment(data).format("DD-MM-YYYY");
    },
    convertDateBACK(data) {
      const newDate = moment(data, "YYYY-MM-DD").toDate();
      return moment(newDate).format("YYYY-MM-DD");
    },
    closeModal() {
      this.$emit("close-modal");
    },
    deleteOption() {
      const payload = {
        employment_history: this.employment_history,
      };
      this.$emit("delete-item", payload);
    },
    submitModal() {
      const payload = {
        employment_history: this.employment_history,
        employer_name: this.employerName,
        address: this.address,
        phone_number: this.telephoneNumber.toString(),
        type_of_business: this.typeOfBusniess,
        job_title: this.jobTitle,
        job_start_date: this.convertDateBACK(this.startDate),
        job_end_date: this.convertDateBACK(this.endDate),
        salary: this.salary.toString(),
        reporting_to: this.reportingTo,
        period_of_notice: this.periodOfNotice,
        can_contact_referee: this.canContactReference,
        reason_for_leaving: this.reasonForLeaving,
        responsibilities_duties_desc: this.responsibilitiesDutiesDesc,
      };
      if (this.getExperiencesInformation) {
        this.$emit(
          "submit-modal",
          "profile/updateExperienceInformation",
          payload
        );
      } else {
        if (this.userID) {
          payload.user = this.userID;
        } else {
          payload.user = this.$store.getters["profile/getProfile"].id;
        }
        payload.is_current = 0;
        this.$emit(
          "submit-modal-create",
          "profile/createExperienceInformation",
          payload
        );
      }
    },
  },
};
</script>

<style scoped>
h3:before,
h3:after {
  content: "";
  color: #000;
  display: inline-block;
  width: 50%;
  margin: 0 0.5em 0 -55%;
  vertical-align: middle;
  border-bottom: 1px solid;
}
h3:after {
  margin: 0 -55% 0 0.5em;
}

input[type="radio"] + label span {
  transition: background-color 0.2s, transform 0.2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
}

input[type="radio"]:checked + label span {
  background-color: #3490dc;
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked + label {
  color: #3490dc;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hideScroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
