<template>
  <form @submit.prevent="submitModal">
    <div
      class="
        flex
        justify-center
        h-screen
        w-screen
        items-center
        z-20
        absolute
        top-0
        left-0
        -translate-x-1/2
      "
    >
      <div
        class="h-screen w-screen bg-grey bg-opacity-60 absolute"
        @click="closeModal"
      ></div>
      <div
        class="
          flex flex-col
          min-w-lg
          max-w-6xl
          mx-auto
          rounded-lg
          border border-lighGrey
          z-20
          overflow-scroll
          h-5/6
          overflow-x-hidden
        "
      >
        <!-- Modal Body -->
        <div class="flex flex-col p-4 bg-white">
          <!-- Title -->
          <h2 class="font-semibold text-teal text-2xl self-center pb-2">
            Employment Information
          </h2>
          <!-- Job Description / Offer Letter-Email / Interview Notes -->
          <div class="md:flex my-1 md:ml-10">
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex mt-3 pb-1"
                for="offerLetter"
                >Offer Letter / Email*</label
              >
              <div class="relative w-full">
                <input
                  type="file"
                  ref="offerLetterFile"
                  name="offerLetter"
                  class="
                    relative
                    text-right
                    opacity-0
                    z-20
                    w-full
                    md:w-11/12
                    h-10
                  "
                  @change="uploadOfferLetter"
                />
                <div class="absolute top-0 left-0 z-10 w-full h-10">
                  <input
                    class="
                      rounded-lg
                      border
                      bg-lightGrey
                      pl-2
                      w-full
                      md:w-11/12
                      h-10
                    "
                    :class="[
                      offerLetterFileSizeOK || v$.offerLetter.$error === true ? 'border-red' : 'border-darkGrey',
                    ]"
                    :placeholder="checkFile(offerLetter, offerLetterFileSizeOK)"
                  />
                </div>
              </div>
              <span v-if="offerLetterFileSizeOK">
                File is Too Large. Please select a different file
              </span>
            </div>
            <!-- Interview Notes -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex mt-2 md:mt-0 pb-1"
                for="interviewNotes"
                >Interview Notes</label
              >
              <textarea
                name="interviewNotes"
                class="
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-full
                  md:w-11/12
                "
                id=""
                rows="5"
                v-model="interviewNotes"
              ></textarea>
            </div>
          </div>
          <!-- Job Advertisement / Health Questionnaire -->
          <div class="md:flex my-1 md:ml-10">
            <!-- Job Advertisement -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex mt-3 pb-1"
                for="jobAdvertisement"
                >Job Advertisement*</label
              >
              <div class="relative w-full">
                <input
                  type="file"
                  ref="jobAdvertisementFile"
                  name="jobAdvertisement"
                  class="
                    relative
                    text-right
                    opacity-0
                    z-20
                    w-full
                    md:w-11/12
                    h-10
                  "
                  @change="uploadJobAdvertisement"
                />
                <div class="absolute top-0 left-0 z-10 w-full h-10">
                  <input
                    class="
                      rounded-lg
                      border
                      bg-lightGrey
                      pl-2
                      w-full
                      md:w-11/12
                      h-10
                    "
                    :class="[
                      jobAdvertisementFileSizeOK || v$.jobAdvertisement.$error === true
                        ? 'border-red'
                        : 'border-darkGrey',
                    ]"
                    :placeholder="
                      checkFile(jobAdvertisement, jobAdvertisementFileSizeOK)
                    "
                  />
                </div>
              </div>
              <span v-if="jobAdvertisementFileSizeOK">
                File is Too Large. Please select a different file
              </span>
            </div>
            <!-- Health Questionnaire -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex mt-3 pb-1"
                for="healthQuestionnaire"
                >Health Questionnaire*</label
              >
              <div class="relative w-full">
                <input
                  type="file"
                  ref="healthQuestionnaireFile"
                  name="healthQuestionnaire"
                  class="
                    relative
                    text-right
                    opacity-0
                    z-20
                    w-full
                    md:w-11/12
                    h-10
                  "
                  @change="uploadHealthQuestionnaire"
                />
                <div class="absolute top-0 left-0 z-10 w-full h-10">
                  <input
                    class="
                      rounded-lg
                      border
                      bg-lightGrey
                      pl-2
                      w-full
                      md:w-11/12
                      h-10
                    "
                    :class="[
                      healthQuestionnaireFileSizeOK || v$.healthQuestionnaire.$error === true
                        ? 'border-red'
                        : 'border-darkGrey',
                    ]"
                    :placeholder="
                      checkFile(
                        healthQuestionnaire,
                        healthQuestionnaireFileSizeOK
                      )
                    "
                  />
                </div>
              </div>
              <span v-if="healthQuestionnaireFileSizeOK">
                File is Too Large. Please select a different file
              </span>
            </div>
          </div>
          <!-- Annual Declaration / Employee Confidentiality Agreement -->
          <div class="md:flex my-1 md:ml-10">
            <!-- Annual Declaration -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex mt-3 pb-1"
                for="annualDeclaration"
                >Annual Declaration*</label
              >
              <div class="relative w-full">
                <input
                  type="file"
                  ref="annualDeclarationFile"
                  name="annualDeclaration"
                  class="
                    relative
                    text-right
                    opacity-0
                    z-20
                    w-full
                    md:w-11/12
                    h-10
                  "
                  @change="uploadAnnualDeclaration"
                />
                <div class="absolute top-0 left-0 z-10 w-full h-10">
                  <input
                    class="
                      rounded-lg
                      border
                      bg-lightGrey
                      pl-2
                      w-full
                      md:w-11/12
                      h-10
                    "
                    :class="[
                      annualDeclarationFileSizeOK || v$.annualDeclaration.$error === true
                        ? 'border-red'
                        : 'border-darkGrey',
                    ]"
                    :placeholder="
                      checkFile(annualDeclaration, annualDeclarationFileSizeOK)
                    "
                  />
                </div>
              </div>
              <span v-if="annualDeclarationFileSizeOK">
                File is Too Large. Please select a different file
              </span>
            </div>
            <!-- Employee Confidentiality Agreement -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex mt-3 pb-1"
                for="employeeConfidentialityAgreement"
                >Employee Confidentiality Agreement*</label
              >
              <div class="relative w-full">
                <input
                  type="file"
                  ref="employeeConfidentialityAgreementFile"
                  name="employeeConfidentialityAgreement"
                  class="
                    relative
                    text-right
                    opacity-0
                    z-20
                    w-full
                    md:w-11/12
                    h-10
                  "
                  @change="uploadEmployeeConfidentialityAgreement"
                />
                <div class="absolute top-0 left-0 z-10 w-full h-10">
                  <input
                    class="
                      rounded-lg
                      border
                      bg-lightGrey
                      pl-2
                      w-full
                      md:w-11/12
                      h-10
                    "
                    :class="[
                      employeeConfidentialityAgreementFileSizeOK || v$.employeeConfidentialityAgreement.$error === true
                        ? 'border-red'
                        : 'border-darkGrey',
                    ]"
                    :placeholder="
                      checkFile(
                        employeeConfidentialityAgreement,
                        employeeConfidentialityAgreementFileSizeOK
                      )
                    "
                  />
                </div>
              </div>
              <span v-if="employeeConfidentialityAgreementFileSizeOK">
                File is Too Large. Please select a different file
              </span>
            </div>
          </div>
          <!-- Employee Privacy Notice / Equipment Provided Policy -->
          <div class="md:flex my-1 md:ml-10">
            <!-- Employee Privacy Notice -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex mt-3 pb-1"
                for="employeePrivacyNotice"
                >Employee Privacy Notice*</label
              >
              <div class="relative w-full">
                <input
                  type="file"
                  ref="employeePrivacyNoticeFile"
                  name="employeePrivacyNotice"
                  class="
                    relative
                    text-right
                    opacity-0
                    z-20
                    w-full
                    md:w-11/12
                    h-10
                  "
                  @change="uploadEmployeePrivacyNotice"
                />
                <div class="absolute top-0 left-0 z-10 w-full h-10">
                  <input
                    class="
                      rounded-lg
                      border
                      bg-lightGrey
                      pl-2
                      w-full
                      md:w-11/12
                      h-10
                    "
                    :class="[
                      employeePrivacyNoticeFileSizeOK || v$.employeePrivacyNotice.$error === true
                        ? 'border-red'
                        : 'border-darkGrey',
                    ]"
                    :placeholder="
                      checkFile(
                        employeePrivacyNotice,
                        employeePrivacyNoticeFileSizeOK
                      )
                    "
                  />
                </div>
              </div>
              <span v-if="employeePrivacyNoticeFileSizeOK">
                File is Too Large. Please select a different file
              </span>
            </div>
            <!-- Equipment Provided Policy -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex mt-3 pb-1"
                for="equipmentProvidedPolicy"
                >Equipment Provided Policy*</label
              >
              <div class="relative w-full">
                <input
                  type="file"
                  ref="equipmentProvidedPolicyFile"
                  name="equipmentProvidedPolicy"
                  class="
                    relative
                    text-right
                    opacity-0
                    z-20
                    w-full
                    md:w-11/12
                    h-10
                  "
                  @change="uploadEquipmentProvidedPolicy"
                />
                <div class="absolute top-0 left-0 z-10 w-full h-10">
                  <input
                    class="
                      rounded-lg
                      border
                      bg-lightGrey
                      pl-2
                      w-full
                      md:w-11/12
                      h-10
                    "
                    :class="[
                      equipmentProvidedPolicyFileSizeOK || v$.equipmentProvidedPolicy.$error === true
                        ? 'border-red'
                        : 'border-darkGrey',
                    ]"
                    :placeholder="
                      checkFile(
                        equipmentProvidedPolicy,
                        equipmentProvidedPolicyFileSizeOK
                      )
                    "
                  />
                </div>
              </div>
              <span v-if="equipmentProvidedPolicyFileSizeOK">
                File is Too Large. Please select a different file
              </span>
            </div>
          </div>
          <!-- Locker Key Agreement / Key Assigned -->
          <div class="md:flex my-1 md:ml-10">
            <!-- Locker Key Agreement -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex mt-3 pb-1"
                for="lockerKeyAgreement"
                >Locker Key Agreement</label
              >
              <div class="relative w-full">
                <input
                  type="file"
                  ref="lockerKeyAgreementFile"
                  name="lockerKeyAgreement"
                  class="
                    relative
                    text-right
                    opacity-0
                    z-20
                    w-full
                    md:w-11/12
                    h-10
                  "
                  @change="uploadLockerKeyAgreement"
                />
                <div class="absolute top-0 left-0 z-10 w-full h-10">
                  <input
                    class="
                      rounded-lg
                      border
                      bg-lightGrey
                      pl-2
                      w-full
                      md:w-11/12
                      h-10
                    "
                    :class="[
                      lockerKeyAgreementFileSizeOK
                        ? 'border-red'
                        : 'border-darkGrey',
                    ]"
                    :placeholder="
                      checkFile(
                        lockerKeyAgreement,
                        lockerKeyAgreementFileSizeOK
                      )
                    "
                  />
                </div>
              </div>
              <span v-if="lockerKeyAgreementFileSizeOK">
                File is Too Large. Please select a different file
              </span>
            </div>
            <!-- Key Assigned -->
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex mt-3 pb-1" for=""
                >Key Assigned</label
              >
              <div class="flex w-full md:w-11/12">
                <div class="mt-2">
                  <label class="inline-flex items-center">
                    <input
                      type="radio"
                      v-model="keyAssigned"
                      name="keyAssigned"
                      :value="1"
                    />
                    <span class="ml-2">Yes</span>
                  </label>
                </div>
                <div class="mt-2 ml-5">
                  <label class="inline-flex items-center">
                    <input
                      type="radio"
                      v-model="keyAssigned"
                      name="keyAssigned"
                      :value="0"
                    />
                    <span class="ml-2">No</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <!-- Equipment Assigned -->
          <div class="md:flex my-1 md:ml-10">
            <div class="w-full">
              <label
                class="font-semibold text-grey flex pb-1"
                for="equipmentList"
                >Equipment List</label
              >
              <!-- Dynamic Equipment List -->
              <div class="my-1">
                <div v-for="(equipment, index) in equipments" :key="equipment">
                  <input
                    type="text"
                    v-model="equipment.name"
                    :name="'equipment' + index"
                    class="
                      rounded-lg
                      border
                      bg-lightGrey
                      pl-2
                      w-full
                      md:w-11.5/12
                      h-10
                    "
                  />
                  <p
                    class="
                      w-20
                      py-1
                      mx-1
                      my-1
                      text-center text-white
                      bg-red
                      rounded-full
                      cursor-pointer
                    "
                    @click.prevent="deleteOption(index)"
                  >
                    Delete
                  </p>
                </div>
                <div class="flex justify-end">
                  <p
                    class="
                      w-28
                      mx-1
                      my-1
                      md:mr-5
                      text-center text-white
                      bg-teal
                      rounded-full
                      cursor-pointer
                    "
                    @click.prevent="addOption"
                  >
                    Add
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- Resume / Proof Of Address -->
          <div class="md:flex my-1 md:ml-10">
            <!-- Resume -->
            <div class="w-full md:w-1/2">
              <label class="font-semibold text-grey flex mt-3 pb-1" for="resume"
                >Resume*</label
              >
              <div class="relative w-full">
                <input
                  type="file"
                  ref="resumeFile"
                  name="resume"
                  class="
                    relative
                    text-right
                    opacity-0
                    z-20
                    w-full
                    md:w-11/12
                    h-10
                  "
                  @change="uploadResume"
                />
                <div class="absolute top-0 left-0 z-10 w-full h-10">
                  <input
                    class="
                      rounded-lg
                      border
                      bg-lightGrey
                      pl-2
                      w-full
                      md:w-11/12
                      h-10
                    "
                    :class="[
                      resumeFileSizeOK || v$.resume.$error === true ? 'border-red' : 'border-darkGrey',
                    ]"
                    :placeholder="checkFile(resume, resumeFileSizeOK)"
                  />
                </div>
              </div>
              <span v-if="resumeFileSizeOK">
                File is Too Large. Please select a different file
              </span>
            </div>
            <!-- Proof Of Address -->
            <div class="w-full md:w-1/2">
              <label
                class="font-semibold text-grey flex mt-3 pb-1"
                for="prooOfAddress"
                >Proof Of Address*</label
              >
              <div class="relative w-full">
                <input
                  type="file"
                  ref="prooOfAddressFile"
                  name="prooOfAddress"
                  class="
                    relative
                    text-right
                    opacity-0
                    z-20
                    w-full
                    md:w-11/12
                    h-10
                  "
                  @change="uploadProoOfAddress"
                />
                <div class="absolute top-0 left-0 z-10 w-full h-10">
                  <input
                    class="
                      rounded-lg
                      border
                      bg-lightGrey
                      pl-2
                      w-full
                      md:w-11/12
                      h-10
                    "
                    :class="[
                      prooOfAddressFileSizeOK || v$.prooOfAddress.$error === true
                        ? 'border-red'
                        : 'border-darkGrey',
                    ]"
                    :placeholder="
                      checkFile(prooOfAddress, prooOfAddressFileSizeOK)
                    "
                  />
                </div>
              </div>
              <span v-if="prooOfAddressFileSizeOK">
                File is Too Large. Please select a different file
              </span>
            </div>
          </div>
        </div>
        <div
          class="
            flex flex-row
            items-center
            justify-end
            p-5
            bg-white
            border-t border-lightGrey
            rounded-bl-lg rounded-br-lg
          "
        >
          <p
            class="
              px-11
              py-2
              text-white
              font-semibold
              bg-orange
              rounded-full
              cursor-pointer
            "
            @click="closeModal"
          >
            Cancel
          </p>
          <base-button :animateButton="animateButton"> {{ animateButton ? '' : 'Post' }} </base-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  props: {
    animateButton: {
      type: Boolean,
      required: true,
    },
    getMiscData: {
      type: Object,
      required: true,
    },
  },
  emits: ["close-modal", "submit-modal"],
  mounted() {
    if (this.getMiscData && this.getMiscData !== null) {
      this.jobSpecification = this.getHiringRequestJobSpecification?.id;
      this.interviewNotes = this.getMiscData.interview_notes;
      this.offerLetter = this.getMiscData.offer_letter_email;
      this.jobAdvertisement = this.getMiscData.job_advertisement;
      this.healthQuestionnaire = this.getMiscData.health_questionnaire;
      this.annualDeclaration = this.getMiscData.annual_declaration;
      this.employeeConfidentialityAgreement =
        this.getMiscData.employee_confidentiality_agreement;
      this.employeePrivacyNotice = this.getMiscData.employee_privacy_notice;
      this.equipmentProvidedPolicy = this.getMiscData.equipment_provided_policy;
      this.lockerKeyAgreement = this.getMiscData.locker_key_agreement;
      this.resume = this.getMiscData.resume;
      this.prooOfAddress = this.getMiscData.proof_of_address;
      this.keyAssigned = this.getMiscData.is_locker_key_assigned;
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      jobSpecification: "",
      interviewNotes: "",
      offerLetter: null,
      offerLetterFileSizeOK: false,
      jobAdvertisement: null,
      jobAdvertisementFileSizeOK: false,
      healthQuestionnaire: null,
      healthQuestionnaireFileSizeOK: false,
      annualDeclaration: null,
      annualDeclarationFileSizeOK: false,
      employeeConfidentialityAgreement: null,
      employeeConfidentialityAgreementFileSizeOK: false,
      employeePrivacyNotice: null,
      employeePrivacyNoticeFileSizeOK: false,
      equipmentProvidedPolicy: null,
      equipmentProvidedPolicyFileSizeOK: false,
      lockerKeyAgreement: null,
      lockerKeyAgreementFileSizeOK: false,
      resume: null,
      resumeFileSizeOK: false,
      prooOfAddress: null,
      prooOfAddressFileSizeOK: false,
      keyAssigned: "",
      equipments: [
        {
          id: 1,
          practice_id: 1,
          name: "Laptop",
          updated_at: "2021-12-14 12:18:53",
          created_at: "2021-12-14 12:18:53",
        },
        {
          id: 2,
          practice_id: 1,
          name: "Tablet",
          updated_at: "2021-12-14 12:18:53",
          created_at: "2021-12-14 12:18:53",
        },
        {
          id: 3,
          practice_id: 1,
          name: "Car",
          updated_at: "2021-12-14 12:18:53",
          created_at: "2021-12-14 12:18:53",
        },
      ],
      jobSpecs: [
        {
          id: 13,
          title: "Job Spec 1",
          updated_at: "2021-12-14 12:10:55",
          created_at: "2021-12-14 12:10:55",
        },
        {
          id: 12,
          title: "Job Spec 2",
          updated_at: "2021-12-14 12:10:55",
          created_at: "2021-12-14 12:10:55",
        },
      ],
    };
  },
  validations() {
    return {
      offerLetter: { required },
      jobAdvertisement: { required },
      healthQuestionnaire: { required },
      annualDeclaration: { required },
      employeeConfidentialityAgreement: { required },
      employeePrivacyNotice: { required },
      equipmentProvidedPolicy: { required },
      resume: { required },
      prooOfAddress: { required },
    };
  },
  methods: {
    currentTime() {
      return moment().format("DD/MM/YYYY");
    },
    uploadOfferLetter() {
      this.offerLetter = this.$refs.offerLetterFile.files[0];
      if (this.offerLetter.size > 1024 * 1024) {
        this.offerLetter = {};
        return (this.offerLetterFileSizeOK = true);
      } else {
        return (this.offerLetterFileSizeOK = false);
      }
    },
    uploadJobAdvertisement() {
      this.jobAdvertisement = this.$refs.jobAdvertisementFile.files[0];
      if (this.jobAdvertisement.size > 1024 * 1024) {
        this.jobAdvertisement = null;
        return (this.jobAdvertisementFileSizeOK = true);
      } else {
        return;
      }
    },
    uploadHealthQuestionnaire() {
      this.healthQuestionnaire = this.$refs.healthQuestionnaireFile.files[0];
      if (this.healthQuestionnaire.size > 1024 * 1024) {
        this.healthQuestionnaire = null;
        return (this.healthQuestionnaireFileSizeOK = true);
      } else {
        return;
      }
    },
    uploadAnnualDeclaration() {
      this.annualDeclaration = this.$refs.annualDeclarationFile.files[0];
      if (this.annualDeclaration.size > 1024 * 1024) {
        this.annualDeclaration = null;
        return (this.annualDeclarationFileSizeOK = true);
      } else {
        return;
      }
    },
    uploadEmployeeConfidentialityAgreement() {
      this.employeeConfidentialityAgreement =
        this.$refs.employeeConfidentialityAgreementFile.files[0];
      if (this.employeeConfidentialityAgreement.size > 1024 * 1024) {
        this.employeeConfidentialityAgreement = null;
        return (this.employeeConfidentialityAgreementFileSizeOK = true);
      } else {
        return;
      }
    },
    uploadEmployeePrivacyNotice() {
      this.employeePrivacyNotice =
        this.$refs.employeePrivacyNoticeFile.files[0];
      if (this.employeePrivacyNotice.size > 1024 * 1024) {
        this.employeePrivacyNotice = null;
        return (this.employeePrivacyNoticeFileSizeOK = true);
      } else {
        return;
      }
    },
    uploadEquipmentProvidedPolicy() {
      this.equipmentProvidedPolicy =
        this.$refs.equipmentProvidedPolicyFile.files[0];
      if (this.equipmentProvidedPolicy.size > 1024 * 1024) {
        this.equipmentProvidedPolicy = null;
        return (this.equipmentProvidedPolicyFileSizeOK = true);
      } else {
        return;
      }
    },
    uploadLockerKeyAgreement() {
      this.lockerKeyAgreement = this.$refs.lockerKeyAgreementFile.files[0];
      if (this.lockerKeyAgreement.size > 1024 * 1024) {
        this.lockerKeyAgreement = null;
        return (this.lockerKeyAgreementFileSizeOK = true);
      } else {
        return;
      }
    },
    uploadResume() {
      this.resume = this.$refs.resumeFile.files[0];
      if (this.resume.size > 1024 * 1024) {
        this.resume = null;
        return (this.resumeSizeOK = true);
      } else {
        return;
      }
    },
    uploadProoOfAddress() {
      this.prooOfAddress = this.$refs.prooOfAddressFile.files[0];
      if (this.prooOfAddress.size > 1024 * 1024) {
        this.prooOfAddress = null;
        return (this.prooOfAddressFileSizeOK = true);
      } else {
        return;
      }
    },
    addOption() {
      this.equipments.push({
        practice_id: 1,
        name: "",
        created_at: this.currentTime(),
      });
    },
    deleteOption(index) {
      this.equipments.splice(index, 1);
    },
    closeModal() {
      this.$emit("close-modal");
    },
    submitModal() {
      this.v$.$validate();
      if(this.v$.$error){
        return;
      }
      const payload = new FormData();
      payload.append("misc_info", this.getMiscData.id);
      payload.append("job_specification", this.jobSpecification);
      payload.append("interview_notes", this.interviewNotes);
      payload.append("is_locker_key_assigned", this.keyAssigned);
      if (typeof this.offerLetter === "object") {
        payload.append("offer_letter_email", this.offerLetter);
      }
      if (typeof this.jobAdvertisement === "object") {
        payload.append("job_advertisement", this.jobAdvertisement);
      }
      if (typeof this.healthQuestionnaire === "object") {
        payload.append("health_questionnaire", this.healthQuestionnaire);
      }
      if (typeof this.annualDeclaration === "object") {
        payload.append("annual_declaration", this.annualDeclaration);
      }
      if (typeof this.employeeConfidentialityAgreement === "object") {
        payload.append(
          "employee_confidentiality_agreement",
          this.employeeConfidentialityAgreement
        );
      }
      if (typeof this.equipmentProvidedPolicy === "object") {
        payload.append(
          "equipment_provided_policy",
          this.equipmentProvidedPolicy
        );
      }
      if (typeof this.employeePrivacyNotice === "object") {
        payload.append("employee_privacy_notice", this.employeePrivacyNotice);
      }
      if (typeof this.lockerKeyAgreement === "object") {
        payload.append("locker_key_agreement", this.lockerKeyAgreement);
      }
      if (typeof this.resume === "object") {
        payload.append("resume", this.resume);
      }

      // const payload = {
      //   misc_info: this.getMiscData.id,
      //   jobSpecification: this.jobSpecification,
      //   interviewNotes: this.interviewNotes,
      //   keyAssigned: this.keyAssigned,
      // };
      // if (this.offerLetter) {
      //   payload.offer_letter_email = this.offerLetter;
      // }
      // if (this.jobAdvertisement) {
      //   payload.job_advertisement = this.jobAdvertisement;
      // }
      // if (this.healthQuestionnaire) {
      //   payload.health_questionnaire = this.healthQuestionnaire;
      // }
      // if (this.annualDeclaration) {
      //   payload.annual_declaration = this.annualDeclaration;
      // }
      // if (this.employeeConfidentialityAgreement) {
      //   payload.employee_confidentiality_agreement =
      //     this.employeeConfidentialityAgreement;
      // }
      // if (this.equipmentProvidedPolicy) {
      //   payload.equipment_provided_policy = this.equipmentProvidedPolicy;
      // }
      // if (this.employeePrivacyNotice) {
      //   payload.employee_privacy_notice = this.employeePrivacyNotice;
      // }
      // if (this.lockerKeyAgreement) {
      //   payload.locker_key_agreement = this.lockerKeyAgreement;
      // }
      // if (this.resume) {
      //   payload.resume = this.resume;
      // }
      // if (this.prooOfAddress) {
      //   payload.proof_of_address = this.prooOfAddress;
      // }
      // if (this.equipmentProvidedPolicy) {
      //   formData.append(
      //     "equipmentProvidedPolicy[]",
      //     this.equipmentProvidedPolicy
      //   );
      // }
      for (var pair of payload.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      this.$emit("submit-modal", "profile/updateMiscInformation", payload);
    },
    checkFile(attachment, sizeCheck) {
      if (typeof attachment === "object" && attachment !== null) {
        if (sizeCheck) {
          return "Click here to attach a file (< 1MB)...";
        } else {
          return attachment.name;
        }
      } else if (attachment !== null) {
        const url = new URL(attachment);
        const newerURL = url.pathname.split("/");
        let lastElement = newerURL[newerURL.length - 1];
        let arr = lastElement.split(".");
        arr.shift();
        lastElement = arr.join(".");
        return lastElement;
      }
      return;
    },
  },
  computed: {
    getHiringRequestJobSpecification() {
      return this.$store.getters["HQnewHire/getHiringRequestJobSpecification"];
    },
  },
};
</script>

<style scoped>
input[type="radio"] + label span {
  transition: background-color 0.2s, transform 0.2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
}

input[type="radio"]:checked + label span {
  background-color: #3490dc;
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked + label {
  color: #3490dc;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hideScroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
