<template>
  <!-- Reference -->
  <div class="">
    <div
      class="recent-activity border-2 border-grey rounded-xl py-2 w-full mt-5 text-darkGrey"
    >
      <div class="flex justify-between mx-3">
        <h2 class="text-teal">References</h2>
        <div v-if="!hideEditButton" class="flex items-start cursor-pointer">
          <span class="material-icons" @click="referencesModal()"> edit </span>
        </div>
      </div>
      <template v-if="getReferencesData && getReferencesData.length > 0">
        <ul class="res-activity-list ml-3 my-2 pl-8 relative">
          <li
            v-for="reference in getReferencesData"
            :key="reference.id"
            class="relative mb-2"
          >
            <p
              class="mb-0 font-bold cursor-pointer"
              @click="referencesModal(reference)"
            >
              {{ reference.referee_name }} ({{ reference.referee_job_title }})
              at
              {{ reference.company_name }}
            </p>
            <p class="text-xs">
              Tel: {{ reference.phone_number }} | eMail:
              {{ reference.referee_email }}
            </p>
          </li>
        </ul>
      </template>
      <div v-else>
        <no-data-found />
      </div>
    </div>
  </div>
</template>

<script>
import noDataFound from "@/components/ui/baseComponents/noDataFound.vue";
export default {
  emits: ["modalTrigger"],
  components: { noDataFound },
  props: {
    getReferencesData: {
      type: Object,
      // required: true,
    },
    hideEditButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showReferencesModal: false,
    };
  },
  methods: {
    referencesModal(data) {
      this.showReferencesModal = !this.showReferencesModal;
      this.$emit("modalTrigger", data);
    },
  },
};
</script>

<style scoped>
.recent-activity .res-activity-list li:before {
  content: "";
  position: absolute;
  border-radius: 100%;
}

.recent-activity .res-activity-list li:before {
  width: 10px;
  height: 10px;
  left: -32px;
  top: 5px;
  border: 2px solid #ed8b00;
  margin-right: 15px;
  z-index: 2;
  background: #fff;
}
.recent-activity .res-activity-list li:hover:before {
  border: 4px solid #ed8b00;
  cursor: pointer;
}
.recent-activity .res-activity-list:after {
  content: "";
  border: 1px solid #e8edee;
  position: absolute;
  top: 5px;
  bottom: 0;
  left: 4px;
}
</style>
