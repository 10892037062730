<template>
  <div class="w-11.5/12 bg-lightGrey rounded-xl border-2 mx-auto pt-2 mt-2">
    <!-- <div
    class="flex-col bg-lightGrey w-11.5/12 rounded-xl border-2 m-auto pt-2 mt-2"
  > -->
    <div class="flex pb-2">
      <!-- Icon -->
      <div class="pl-2 sm:flex sm:items-center">
        <a href="#" class="block relative">
          <img
            v-if="profileData.profile_image"
            @error="setAltImg"
            :src="profileData.profile.profile_image"
            class="mx-auto object-cover rounded-full w-20 h-20 sm:w-36 sm:h-36"
          />
          <img
            v-else
            alt="Profile"
            src="../../../assets/1.png"
            class="mx-auto object-cover rounded-full w-20 h-20 sm:w-36 sm:h-36"
          />
        </a>
      </div>
      <!-- Left Info section -->
      <div class="flex-1 md:mx-5 pl-2 text-sm">
        <div class="font-bold text-md">
          {{ profileData.profile.first_name }}
          {{ profileData.profile.middle_name }}
          {{ profileData.profile.last_name }}
        </div>
        <div class="">
          <p class="text-grey inline">Position:&nbsp;</p>
          <p class="inline">{{ profileData.profile.primary_role }}</p>
        </div>
        <div class="">
          <p class="text-grey inline">Roles:&nbsp;</p>
          <p class="inline">Safeguarding Lead, Head GP</p>
        </div>
        <div class="">
          <p class="text-grey inline">Phone:&nbsp;</p>
          <p class="inline">
            H: {{ profileData.profile.home_phone }} | T:
            {{ profileData.profile.mobile_phone }} | W:
            {{ profileData.profile.work_phone }}
          </p>
        </div>
        <div class="hidden sm:block">
          <p class="text-grey inline">Email:&nbsp;</p>
          <p class="inline">
            {{ profileData.email }} |
            {{ profileData.profile.professional_email }}
          </p>
        </div>
        <div class="hidden sm:block">
          <p class="text-grey inline">NHS Number:&nbsp;</p>
          <p class="inline">
            {{ profileData.profile.nhs_number }}
          </p>
        </div>
        <div class="hidden sm:block">
          <p class="text-grey inline">NHS Card:&nbsp;</p>
          <p class="inline">
            {{ profileData.profile.nhs_card }}
          </p>
        </div>
      </div>
      <!-- Right Info section -->
      <div
        class="
          hidden
          flex-1
          md:block
          border-l-2
          pr-1
          border-dashed border-darkGrey
          p-2
          text-sm
        "
      >
        <div class="">
          <p class="text-grey inline">Address:&nbsp;</p>
          <p class="inline">
            {{ profileData.profile.address_line_1 }},
            {{ profileData.profile.address_line_2 }},
            {{ profileData.profile.city }}
          </p>
          <p class="">
            {{ profileData.profile.county }}, {{ profileData.profile.country }},
            {{ profileData.profile.zip_code }}
          </p>
        </div>
        <div class="">
          <p class="text-grey inline">Middle Name:&nbsp;</p>
          <p class="inline">M.</p>
        </div>
        <div class="">
          <p class="text-grey inline">Maiden Name:&nbsp;</p>
          <p class="inline">
            {{ profileData.profile.maiden_name }}
          </p>
        </div>
        <div class="">
          <p class="text-grey inline">Birthday:&nbsp;</p>
          <p class="inline">
            {{ profileData.profile.dob }}
          </p>
        </div>
        <div class="">
          <p class="text-grey inline">Gender:&nbsp;</p>
          <p class="inline">
            {{ profileData.profile.gender }}
          </p>
        </div>
        <div class="">
          <p class="text-grey inline">COVID Vaccination:&nbsp;</p>
          <p class="inline">1st: 1st Jan 2019 | 2nd: 1st Apr 2019</p>
        </div>
      </div>
      <!-- Edit -->
      <div v-if="!hideModalButton" class="flex items-start cursor-pointer">
        <span class="material-icons" @click="personalInfoModal()"> edit </span>
      </div>
    </div>
    <!-- Tabs -->
    <div
      v-if="!hideTabs"
      class="
        overflow-x-scroll
        flex
        xl:justify-center
        text-darkGrey text-center
        pt-2
        text-sm
      "
    >
      <!-- <div
      class="flex-wrap md:flex-nowrap md:flex w-full text-darkGrey text-center pt-2 text-sm"
    > -->
      <div
        class="flex py-1 hover:bg-white hover:text-teal cursor-pointer"
        :class="[
          localSelectedComponent == 'profile' ? 'text-teal bg-white' : '',
        ]"
        @click="setSelectedComponent('profile')"
      >
        <div class="flex justify-center items-center flex-grow w-36 px-2">
          <span> Profile</span>
        </div>
      </div>
      <div
        class="flex py-1 hover:bg-white hover:text-teal cursor-pointer"
        :class="[
          localSelectedComponent == 'employment-hr' ? 'text-teal bg-white' : '',
        ]"
        @click="setSelectedComponent('employment-hr')"
      >
        <div class="flex justify-center items-center flex-grow w-36 px-2">
          <span> Employment / HR</span>
        </div>
      </div>
      <div
        class="flex py-1 hover:bg-white hover:text-teal cursor-pointer"
        :class="[
          localSelectedComponent == 'sickness-leave'
            ? 'text-teal bg-white'
            : '',
        ]"
        @click="setSelectedComponent('sickness-leave')"
      >
        <div class="flex justify-center items-center flex-grow w-36 px-2">
          <span> Sickness / Leave</span>
        </div>
      </div>
      <div
        class="flex py-1 hover:bg-white hover:text-teal cursor-pointer"
        :class="[
          localSelectedComponent == 'payroll-banking'
            ? 'text-teal bg-white'
            : '',
        ]"
        @click="setSelectedComponent('payroll-banking')"
      >
        <div class="flex justify-center items-center flex-grow w-36 px-2">
          <span>Payroll / Banking</span>
        </div>
      </div>
      <div
        class="flex py-1 hover:bg-white hover:text-teal cursor-pointer"
        :class="[
          localSelectedComponent == 'training' ? 'text-teal bg-white' : '',
        ]"
        @click="setSelectedComponent('training')"
      >
        <div class="flex justify-center items-center flex-grow w-36 px-2">
          <span>Training</span>
        </div>
      </div>
      <div
        class="flex py-1 hover:bg-white hover:text-teal cursor-pointer"
        :class="[
          localSelectedComponent == 'appraisals' ? 'text-teal bg-white' : '',
        ]"
        @click="setSelectedComponent('appraisals')"
      >
        <div class="flex justify-center items-center flex-grow w-36 px-2">
          <span>Appraisals</span>
        </div>
      </div>
      <div
        class="flex py-1 hover:bg-white hover:text-teal cursor-pointer"
        :class="[
          localSelectedComponent == 'correspondence'
            ? 'text-teal bg-white'
            : '',
        ]"
        @click="setSelectedComponent('correspondence')"
      >
        <div class="flex justify-center items-center flex-grow w-36 px-2">
          <span>Correspondence</span>
        </div>
      </div>
      <div
        class="flex py-1 hover:bg-white hover:text-teal cursor-pointer"
        :class="[localSelectedComponent == 'notes' ? 'text-teal bg-white' : '']"
        @click="setSelectedComponent('notes')"
      >
        <div class="flex justify-center items-center flex-grow w-36 px-2">
          <span>Notes</span>
        </div>
      </div>
      <div
        class="flex py-1 hover:bg-white hover:text-teal cursor-pointer"
        :class="[
          localSelectedComponent == 'documents' ? 'text-teal bg-white' : '',
        ]"
        @click="setSelectedComponent('documents')"
      >
        <div class="flex justify-center items-center flex-grow w-36 px-2">
          <span>Documents</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["selectedTab", "personalInfoModal"],

  props: {
    profileData: {
      type: Object,
      required: true,
    },
    selectedComponent: {
      type: String,
    },
    hideTabs: {
      type: Boolean,
      default: false,
    },
    hideModalButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localSelectedComponent: this.selectedComponent,
      showPersonalInfoModal: false,
      imageError: false,
    };
  },
  methods: {
    setAltImg() {
      this.imageError = true;
    },
    setSelectedComponent(data) {
      this.localSelectedComponent = data;
      this.$emit("selectedTab", this.localSelectedComponent);
    },
    personalInfoModal() {
      this.showPersonalInfoModal = !this.showPersonalInfoModal;
      this.$emit("personalInfoModal", this.showPersonalInfoModal);
    },
  },
  watch: {
    selectedComponent() {
      return (this.localSelectedComponent = this.selectedComponent);
    },
  },
};
</script>
