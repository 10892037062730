<template>
  <!-- Experience Information -->
  <div
    class="recent-activity border-2 border-grey rounded-xl py-2 w-full mt-5 text-darkGrey"
  >
    <div class="flex justify-between mx-3">
      <h2 class="text-teal">Experience Information</h2>
      <div v-if="!hideEditButton" class="flex items-start cursor-pointer">
        <span class="material-icons" @click="experienceModal()"> edit </span>
      </div>
    </div>
    <template
      v-if="getExperiencesInformation && getExperiencesInformation.length > 0"
    >
      <ul class="res-activity-list ml-3 my-2 pl-8 relative">
        <span
          v-for="experience in getExperiencesInformation"
          :key="experience.id"
        >
          <li class="relative mb-2">
            <p
              class="mb-0 font-bold cursor-pointer"
              @click="experienceModal(experience)"
            >
              {{ experience.job_title }} at {{ experience.type_of_business }}
            </p>

            <p class="text-xs">
              {{ dateConvert(experience.job_start_date) }} -
              {{ dateConvert(experience.job_end_date) }}
            </p>
          </li>
        </span>
      </ul>
    </template>
    <div v-else>
      <no-data-found />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import noDataFound from "@/components/ui/baseComponents/noDataFound.vue";
export default {
  emits: ["modalTrigger"],
  components: { noDataFound },
  props: {
    getExperiencesInformation: {
      type: Object,
      // required: true,
    },
    hideEditButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showExperienceModal: false,
    };
  },
  methods: {
    experienceModal(data) {
      this.showExperienceModal = !this.showExperienceModal;
      this.$emit("modalTrigger", data);
    },
    dateConvert(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
  watch: {
    getExperiencesInformation() {
      return console.log("inside");
    },
  },
};
</script>

<style scoped>
.recent-activity .res-activity-list li:before {
  content: "";
  position: absolute;
  border-radius: 100%;
}

.recent-activity .res-activity-list li:before {
  width: 10px;
  height: 10px;
  left: -32px;
  top: 5px;
  border: 2px solid #ed8b00;
  margin-right: 15px;
  z-index: 2;
  background: #fff;
}
.recent-activity .res-activity-list li:hover:before {
  border: 4px solid #ed8b00;
  cursor: pointer;
}
.recent-activity .res-activity-list:after {
  content: "";
  border: 1px solid #e8edee;
  position: absolute;
  top: 5px;
  bottom: 0;
  left: 4px;
}
</style>
